import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { IRecipient } from '@/usecase/recipient/model/IRecipient'

export interface CreateRecipientsParam {
  list: Array<IRecipient>
}

export interface CreateRecipients extends IApiRequest {
  getInstance (): CreateRecipients;
  request (data: CreateRecipientsParam): Promise<CreateRecipientsParam>;
}

export class CreateRecipients implements CreateRecipients {
  private static instance: CreateRecipients
  private constructor () {}
  public static getInstance (): CreateRecipients {
    if (!this.instance) this.instance = new CreateRecipients()
    return this.instance
  }

  async request (data: CreateRecipientsParam): Promise<CreateRecipientsParam> {
    const requestOption: RequestOption = {
      url: 'remittance/recipients/create',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response.data
  }
}
