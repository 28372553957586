import { RecipientField, RecipientSkeletonFields } from '@/gateway/recipient/model/RecipientSkeletonFields'
import { ReceiveMethodEnum, RecipientFieldEnum } from '@/enums'
import { ParsedRecipient } from '@/presentation/recipient/model/ParsedRecipient'
import { CheckingInvalidRecipient } from '@/presentation/recipient/model/CheckingInvalidRecipient'
import { RecipientValidateOptions } from '@/entity/validator/model/RecipientValidateOptions'
import { RecipientRules } from '@/entity/validator/data/RecipientRules'

export interface IValidatorEntity {}

class ValidatorEntity implements IValidatorEntity {}

export interface IRecipientValidatorEntity {
  addErrorAttribute(recipient: Dictionary<any>, fieldName: string, message: string): CheckingInvalidRecipient
  isValidExcelHeaders(
    parsedRecipient: Dictionary<Array<ParsedRecipient>>,
    countriesRecipientFields: Array<RecipientSkeletonFields>
  ): boolean
  setDuplicateRecipient(recipient: CheckingInvalidRecipient): void
  hasDuplicateRecipient(recipient: CheckingInvalidRecipient): boolean
  recipientValidate(validateOption: RecipientValidateOptions): boolean
}

export class RecipientValidatorEntity extends ValidatorEntity implements IRecipientValidatorEntity {
  private duplicateRecipientIds: Map<number, string>
  private recipientRules: RecipientRules

  constructor() {
    super()
    this.duplicateRecipientIds = new Map<number, string>()
    this.recipientRules = new RecipientRules()
  }

  private getSkeletonFieldNames(skeletonFields: Array<RecipientField>): Array<string> {
    const skeletonNames = skeletonFields.map(field => {
      return RecipientFieldEnum[field.id].toLowerCase()
    })
    return skeletonNames
  }

  private checkWithSkeletonField(headers: Array<string>, skeletonFields: Array<RecipientField>): boolean {
    const skeletonFieldNames = this.getSkeletonFieldNames(skeletonFields)
    const result = skeletonFieldNames.every(name => {
      return headers.includes(name)
    })
    return result
  }

  isValidExcelHeaders(
    parsedRecipient: Dictionary<Array<ParsedRecipient>>,
    countriesRecipientFields: Array<RecipientSkeletonFields>
  ): boolean {
    let isValidHeaders = true
    for (const [countryWithMethod, recipientFieldList] of Object.entries(parsedRecipient)) {
      if (!isValidHeaders) break
      const country = countryWithMethod.includes('CN_USD') ? 'HK' : countryWithMethod.split('_')[0].toUpperCase()
      const method = countryWithMethod.substring(countryWithMethod.indexOf('_') + 1)
      const methodCode = ReceiveMethodEnum[method.toUpperCase() as keyof typeof ReceiveMethodEnum]
      const sheetHeaders = Object.keys(recipientFieldList[0])
      let skeletonFields = countriesRecipientFields
        .find(skeleton => skeleton.country === country && skeleton.receive_method === methodCode)?.fields
        || countriesRecipientFields.find(skeleton => skeleton.country === 'ZZ' )?.fields
      if (!skeletonFields) {
        isValidHeaders = false
        continue
      }
      isValidHeaders = this.checkWithSkeletonField(sheetHeaders, skeletonFields)
    }
    return isValidHeaders
  }

  private removeErrorAttribute(recipient: Dictionary<any>, fieldName: string): CheckingInvalidRecipient {
    delete recipient[`${fieldName}_class`]
    delete recipient[`${fieldName}_message`]
    return recipient as CheckingInvalidRecipient
  }

  public addErrorAttribute(recipient: Dictionary<any>, fieldName: string, message: string): CheckingInvalidRecipient {
    recipient[`${fieldName}_class`] = 'is-error'
    recipient[`${fieldName}_message`] = message
    if (!recipient.updatable) recipient.updatable = true
    return recipient as CheckingInvalidRecipient
  }

  public setDuplicateRecipient(recipient: CheckingInvalidRecipient): void {
    this.duplicateRecipientIds.set(recipient.id, recipient.corps_id)
  }

  public hasDuplicateRecipient(checkingInvalidRecipient: CheckingInvalidRecipient): boolean {
    const corpsId = checkingInvalidRecipient.corps_id
    const fieldName = 'corps_id'
    const recipient = checkingInvalidRecipient as Dictionary<any>
    this.duplicateRecipientIds.set(checkingInvalidRecipient.id, corpsId)
    let corpsIdCount = 0
    for (const existCorpsId of Array.from(this.duplicateRecipientIds.values())) {
      if (corpsId === existCorpsId) corpsIdCount++
    }
    const hasDuplicated = corpsIdCount > 1
    const message = 'corps_id is duplicated'
    if (hasDuplicated) {
      this.addErrorAttribute(recipient, fieldName, message)
      return true
    }
    if (recipient.corps_id_message === message) this.removeErrorAttribute(recipient, fieldName)
    return false
  }

  public recipientValidate(validateOption: RecipientValidateOptions): boolean {
    let isValidatedAll = true
    const skeletonFieldNames = this.getSkeletonFieldNames(validateOption.skeletonField)
    const recipient = validateOption.recipient as Dictionary<any>
    for (const [fieldName, rules] of Object.entries(RecipientRules.rules)) {
      const hasToFieldExist = skeletonFieldNames.includes(fieldName.toLowerCase())
      if (!hasToFieldExist) continue
      rules.forEach(rule => {
        const typeMethod = this.recipientRules.setRuleValidateMethods[rule.type]
        if (!typeMethod) return
        const isValid = typeMethod(fieldName, validateOption, rule)
        if (isValid) this.removeErrorAttribute(recipient, fieldName)
        else this.addErrorAttribute(recipient, fieldName, rule.message)
        isValidatedAll = isValidatedAll && isValid
      })
    }
    return isValidatedAll
  }
}
