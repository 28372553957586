export enum UploadValidResponse {
  // 200
  SUCCESS = 200,
  // 400
  FAIL = 400,
  EMPTY_SHEETS = 404,
  NOT_ANALOGUE_CORP_WITH_OTHERS,
  INVALID_COLUMN_HEADERS,
  NO_ACCEPTABLE_COUNTRIES,
  // 500
  SERVER_ERROR = 500
}
