import { IRecipientEntity, RecipientEntity } from '@/entity/recipient/RecipientEntity'
import { ParsedRecipient } from '@/presentation/recipient/model/ParsedRecipient'
import { IRecipientValidatorEntity, RecipientValidatorEntity } from '@/entity/validator/ValidatorEntity'
import { ReceivableCountries } from '@/gateway/remittanceBase/ReceivableCountries'
import { RecipientField, RecipientSkeletonFields } from '@/gateway/recipient/model/RecipientSkeletonFields'
import { GetRecipientFieldSkeleton, RecipientFieldSkeletonParam } from '@/gateway/recipient/GetRecipientFieldSkeleton'
import { UploadValidResponse } from '@/usecase/recipient/model/UploadValidResponse'
import { CheckingInvalidRecipient } from '@/presentation/recipient/model/CheckingInvalidRecipient'
import CountryEnum from '@/enums/CountryEnum'
import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { RecipientValidateOptions } from '@/entity/validator/model/RecipientValidateOptions'
import { GetRemittanceBase } from '@/gateway/remittanceBase/GetRemittanceBase'
import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'
import RecipientFieldEnum from '@/enums/RecipientFieldEnum'
import { CreateRecipients, CreateRecipientsParam } from '@/gateway/recipient/CreateRecipients'
import RecipientSheetHandler from '@/lib/RecipientSheetHandler'
import { AvailableCurrencies } from '@/gateway/remittanceBase/AvailableCurrencies'
import { ReceiveMethodEnum } from '@/enums'

export interface IRecipientUseCase {
  getRecipientSkeletonFields(): Promise<Array<RecipientSkeletonFields>>
  isAnalogueCorp(): Promise<boolean>
  getReceivableCountries(): Promise<Array<keyof typeof CountryEnum>>
  getRecipientFromExcel(parsedJson: Dictionary<any>): Promise<Dictionary<Array<ParsedRecipient>>>
  isValidExcel(parsedRecipient: Dictionary<Array<ParsedRecipient>>): Promise<UploadValidResponse>
  setDuplicateRecipient(recipient: CheckingInvalidRecipient): void
  validateRecipient(country: keyof typeof CountryEnum, method: keyof typeof ReceiveMethodEnum, recipient: CheckingInvalidRecipient): Promise<boolean>
  hasDuplicateRecipient(recipient: CheckingInvalidRecipient): boolean
  registerRecipients(createRecipientsParam: CreateRecipientsParam): Promise<boolean>
  downloadRecipientSheet(selectedCountries: Array<string>, selectedOtherCountries: Array<string>): Promise<void>
}

export class RecipientUseCase implements IRecipientUseCase {
  private recipientEntity: IRecipientEntity
  private recipientValidator: IRecipientValidatorEntity
  private readonly recipientSkeletonFields: Array<RecipientSkeletonFields>
  private remittanceBase!: Array<IRemittanceBase>
  private sheetHandler: RecipientSheetHandler
  constructor() {
    this.recipientEntity = new RecipientEntity()
    this.recipientValidator = new RecipientValidatorEntity()
    this.recipientSkeletonFields = []
    this.sheetHandler = new RecipientSheetHandler()
  }

  private async getRemittanceBase(): Promise<Array<IRemittanceBase>> {
    if (!this.remittanceBase) {
      this.remittanceBase = await GetRemittanceBase.getInstance().request()
    }
    return this.remittanceBase
  }

  /**
   * 수취국가 + 모든국가(ZZ) + CN_USD 로 skeleton 정보 조회
   * skeleton 정보(수취국가 + 수취수단 + 필수필드)
   * => (필수필드) skeleton 정책과 무관한 필수필드 + skeleton 정책의 필수필드를 리턴
   * @returns {Promise<Array<RecipientSkeletonFields>>} 
   */
  async getRecipientSkeletonFields(): Promise<Array<RecipientSkeletonFields>> {
    const isSkeletonEmpty = !this.recipientSkeletonFields.length
    if (isSkeletonEmpty) {
      const countries = await this.getReceivableCountries()
      const skeletonParam: RecipientFieldSkeletonParam = { countries: [...countries, 'ZZ'] }
      skeletonParam.countries.push('CN_USD')
      const recipientFieldSkeleton = await GetRecipientFieldSkeleton.getInstance().request(skeletonParam)
      const corpsIdField: RecipientField = { id: RecipientFieldEnum.CORPS_ID, optional: false }
      const phoneCodeField: RecipientField = { id: RecipientFieldEnum.PHONE_CODE, optional: false }
      const baseCurrencyField: RecipientField = { id: RecipientFieldEnum.BASE_CURRENCY, optional: false }
      
      this.recipientSkeletonFields.push( ...recipientFieldSkeleton.map(skeleton => {
        return {
          ...skeleton,
          fields: [
            corpsIdField,
            phoneCodeField,
            ...skeleton.fields,
            baseCurrencyField
          ]
        }

      }))
    }
    return this.recipientSkeletonFields
  }

  async isAnalogueCorp(): Promise<boolean> {
    const corpInfo = await GetCorpInformation.get()
    return corpInfo.analogue
  }

  async getReceivableCountries(): Promise<Array<keyof typeof CountryEnum>> {
    const receivableCountries = await ReceivableCountries.get()
    return receivableCountries
  }

  async getRecipientFromExcel(parsedJson: Dictionary<any>): Promise<Dictionary<Array<ParsedRecipient>>> {
    const parsedSheetNames: Array<string> = Object.keys(parsedJson)
    const validSheets: Array<string> = this.recipientEntity.filterRecipientSheetNames(parsedSheetNames)
    const parsedRecipient: Dictionary<Array<ParsedRecipient>> = {}
    validSheets.forEach(sheetName => {
      parsedRecipient[sheetName] = parsedJson[sheetName]
    })
    return parsedRecipient
  }

  async isValidExcel(parsedRecipient: Dictionary<Array<ParsedRecipient>>): Promise<UploadValidResponse> {
    const isAnalogueCorp = await this.isAnalogueCorp()
    const sheetNames: Array<string> = Object.keys(parsedRecipient)
    if (!sheetNames.length) return UploadValidResponse.EMPTY_SHEETS
    const receivableCountries = await this.getReceivableCountries()
    const countries = this.recipientEntity.getBaseMathcedCountries(sheetNames, receivableCountries, isAnalogueCorp)
    const isMatchedSheetsAndCountries = countries.length === sheetNames.length
    if (!isAnalogueCorp && !isMatchedSheetsAndCountries) return UploadValidResponse.NOT_ANALOGUE_CORP_WITH_OTHERS
    if (!countries.length) return UploadValidResponse.NO_ACCEPTABLE_COUNTRIES
    const countryRecipientField = await this.getRecipientSkeletonFields()
    if (!countryRecipientField.length) return UploadValidResponse.SERVER_ERROR
    const headersInvalid = this.recipientValidator.isValidExcelHeaders(parsedRecipient, countryRecipientField)
    if (!headersInvalid) return UploadValidResponse.INVALID_COLUMN_HEADERS
    return UploadValidResponse.SUCCESS
  }

  setDuplicateRecipient(recipient: CheckingInvalidRecipient) {
    this.recipientValidator.setDuplicateRecipient(recipient)
  }

  async validateRecipient(country: keyof typeof CountryEnum, method: keyof typeof ReceiveMethodEnum, recipient: CheckingInvalidRecipient): Promise<boolean> {
    const corpInfo = await GetCorpInformation.get()
    const remittanceBases = await this.getRemittanceBase()
    const remittanceBase = remittanceBases.find(base => base.country === country)
    const skeletonFields = await this.getRecipientSkeletonFields()
    const skeletonField = skeletonFields.find(skeleton => skeleton.country === country && skeleton.receive_method === ReceiveMethodEnum[method])?.fields || 
      skeletonFields.find(skeleton => skeleton.country === 'ZZ')!.fields
    const recipientValidationOptions: RecipientValidateOptions = {
      country,
      isAnalogueCorp: corpInfo.analogue,
      recipient,
      remittanceBase,
      skeletonField,
      method: ReceiveMethodEnum[method]
    }
    const isValidRecipient = await this.recipientValidator.recipientValidate(recipientValidationOptions)
    return isValidRecipient
  }

  hasDuplicateRecipient(recipient: CheckingInvalidRecipient): boolean {
    const hasDuplicated = this.recipientValidator.hasDuplicateRecipient(recipient)
    return hasDuplicated
  }

  async registerRecipients(createRecipientsParam: CreateRecipientsParam): Promise<boolean> {
    const response = await CreateRecipients.getInstance().request(createRecipientsParam)
    const failUploadRecipients = response?.list
    const isFailUpload = !failUploadRecipients || !!failUploadRecipients.length
    return isFailUpload
  }

  private getOtherCountryFields(
    recipientFields: Array<RecipientSkeletonFields>,
    selectedOtherCountries: Array<string>
  ): Array<RecipientSkeletonFields> {
    const anotherCountryField = recipientFields.find(fields => fields.country === 'ZZ')
    const exceptAnotherCountryFields = recipientFields.filter(fields => fields.country !== 'ZZ')
    const otherCountries = selectedOtherCountries.map(country => {
      return Object.assign({}, anotherCountryField, { country })
    })
    return [...exceptAnotherCountryFields, ...otherCountries]
  }

  async downloadRecipientSheet(selectedCountries: Array<string>, selectedOtherCountries: Array<string>): Promise<void> {
    const hasOtherCountries = selectedOtherCountries.length
    if (hasOtherCountries) selectedCountries.push('ZZ')
    const params: RecipientFieldSkeletonParam = { countries: selectedCountries as Array<keyof typeof CountryEnum> }
    if (params.countries.includes('CN')) params.countries.push('CN_USD')
    let recipientFields: Array<RecipientSkeletonFields> = await GetRecipientFieldSkeleton.getInstance().request(params)
    this.sheetHandler = new RecipientSheetHandler()
    const hasOtherCountryFields = recipientFields.some(fields => fields.country === 'ZZ')
    if (hasOtherCountryFields) recipientFields = this.getOtherCountryFields(recipientFields, selectedOtherCountries)
    const receivableCurrencies = await AvailableCurrencies.getReceivableCurrencies()
    const sheetJson = this.sheetHandler.makeSheetJson(recipientFields, receivableCurrencies)
    const hasSheetFields = Object.keys(sheetJson).length
    if (!hasSheetFields) return
    if (hasOtherCountryFields) this.sheetHandler.setSheetJsonToExportWithOthers(sheetJson, selectedOtherCountries)
    else this.sheetHandler.setSheetJsonToExport(sheetJson)
    this.sheetHandler.saveSheetByJson('recipient.xlsx', {
      useCellDataOption: false,
      cellDataOptions: { account_number: { type: 's' } }
    })
  }
}
