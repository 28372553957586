import CountryEnum from '@/enums/CountryEnum'
import { Utils } from '@/static/Utils'
import { ReceiveMethodEnum } from '@/enums'

export interface IRecipientEntity {
  getBaseMathcedCountries(
    sheetNames: Array<string>,
    receivableCountries: Array<string>,
    isAnalogue: boolean
  ): Array<keyof typeof CountryEnum>
  filterRecipientSheetNames(sheetNames: Array<string>): Array<string>
}

export class RecipientEntity implements IRecipientEntity {
  getBaseMathcedCountries(
    sheetNames: Array<string>,
    receivableCountries: Array<string>,
    isAnalogue: boolean
  ): Array<keyof typeof CountryEnum> {
    const countries: Array<keyof typeof CountryEnum> = []
    sheetNames.forEach(sheetName => {
      const country = sheetName.split('_')[0].toUpperCase()
      if (!receivableCountries.includes(country) && !isAnalogue) return
      countries.push(country as keyof typeof CountryEnum)
    })
    return countries
  }

  filterRecipientSheetNames(sheetNames: Array<string>): Array<string> {
    return sheetNames.filter((sheetName: string) => {
      const countryWithMethod: Array<string> = sheetName.split('_')
      const country: string = countryWithMethod[0]
      const method: string = sheetName.includes(`${country}_`) ? sheetName.replace(`${country}_`, '') : ''
      const currencyFilteredMethod: string = method.includes('USD_ANYWHERE') ? method : method.replace('USD_', '')
      const hasMethod = Utils.isValidEnumKey(currencyFilteredMethod, ReceiveMethodEnum)
      const hasCountry = country.length === 2
      return hasMethod && hasCountry
    })
  }
}
